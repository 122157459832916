import React from 'react';
import DownloadFree from '../containers/sections/DownloadFree/DownloadFree';
import { css } from 'aphrodite';
import STYLES from '../styles/shared';
import Layout from '../containers/gatsby/layout';
import { FONTS } from '../styles/fonts';
import COLORS from '../styles/colors';

const CookiesPolicyPage = (props) => (
  <Layout location={props.location}>
    <div className={css(STYLES.documentContent)}>
      <h1>Cookies Policy</h1>
      <p style={{fontFamily: FONTS.Content, color: COLORS.jjdGreyish}} className={'intro'}>Our Cookies Policy explains what cookies are, why cookies are used, which cookies are
        used on the JJD Website and how you can manage cookies used on the Website.</p>
      <h3>General</h3>
      <p style={{fontFamily: FONTS.Content, color: COLORS.jjdGreyish}}>We use cookies and similar tracking technologies to track the activity on our Service and hold certain
        information. Cookies are files with small amount of data which may include an anonymous unique identifier.
        Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used
        are beacons, tags, and scripts to collect and track information and to improve and analyze our Service.
        You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However,
        if you do not accept cookies, you may not be able to use some portions of our Service.
      </p>
    </div>
    <DownloadFree/>
  </Layout>
);

export default CookiesPolicyPage;
